@import 'svg';

%t-box-shadow-1 {
	box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}

%t-box-shadow-2 {
	box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
}

%t-box-shadow-3 {
	box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 0 6px 6px rgba(0, 0, 0, .23);
}

%t-box-shadow-4 {
	box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
}

%t-box-shadow-5 {
	box-shadow: 0 19px 38px rgba(0, 0, 0, .3), 0 15px 12px rgba(0, 0, 0, .22);
}

body {
	margin: 0;
	padding: 30px 20px;
	font-family: sans-serif;
	background-color: rgba(#f4a03d, .1);

	&::before {
		@extend %t-box-shadow-1;
		content: '';
		display: block;
		height: 5px;
		background-color: #f4a03d;
		border-bottom: 1px solid #D18338;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.page {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.logo {
	max-width: 100%;
}

.box {
	padding: 25px;
	width: 100%;
	max-width: 800px;
	border-radius: 5px;
}

h1,
h2 {
	margin: 0 0 15px;
	padding: 0;
	text-align: center;
}

h2 {
	margin-bottom: 40px;
}

.list-wrapper {
	text-align: center;
}

ul {
	margin: 0 0 50px;
	padding: 0;
	list-style: none;
	display: inline-block;
	justify-content: space-around;
	flex-wrap: wrap;

	li {
		margin: 0 0 15px;
		padding-left: 25px;
		font-size: 22px;
		text-align: left;
		position: relative;

		&::before {
			margin: 0 5px;
			content: '·';
			font-weight: 700;
			font-size: 30px;
			display: inline-block;
			position: absolute;
			left: 0;
			top: -2px;
		}
	}
}

address {
	font-style: normal;
	font-size: 22px;
	text-align: center;
}

.button-group {
	padding-top: 35px;
}

.button {
	@extend %t-box-shadow-1;
	margin: 0 5px 10px;
	padding: 15px 15px 15px 50px;
	display: inline-block;
	text-decoration: none;
	background-color: #f4a03d;
	color: #000;
	border-radius: 5px;
	position: relative;
	text-align: left;
	font-size: 20px;
	border-bottom: 1px solid #242657;

	&--phone::before {
		@include inline-svg($phone);
		content: '';
		display: block;
		position: absolute;
		left: 15px;
		top: 12px;
	}

	&--mail {
		padding-left: 58px;

		&::before {
			@include inline-svg($mail);
			content: '';
			display: block;
			position: absolute;
			left: 15px;
			top: 12px;
		}
	}
}

.imprint {
	display: flex;
	width: 100%;
	justify-content: center;

	.box {
		display: inline-block;
		width: auto;
	}
}
