@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

$mail: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3e%3cpath fill='white' d='M28 11.094V23.5c0 1.375-1.125 2.5-2.5 2.5h-23A2.507 2.507 0 0 1 0 23.5V11.094c.469.516 1 .969 1.578 1.359 2.594 1.766 5.219 3.531 7.766 5.391 1.313.969 2.938 2.156 4.641 2.156h.031c1.703 0 3.328-1.188 4.641-2.156 2.547-1.844 5.172-3.625 7.781-5.391a9.278 9.278 0 0 0 1.563-1.359zM28 6.5c0 1.75-1.297 3.328-2.672 4.281-2.438 1.687-4.891 3.375-7.313 5.078-1.016.703-2.734 2.141-4 2.141h-.031c-1.266 0-2.984-1.437-4-2.141-2.422-1.703-4.875-3.391-7.297-5.078-1.109-.75-2.688-2.516-2.688-3.938 0-1.531.828-2.844 2.5-2.844h23c1.359 0 2.5 1.125 2.5 2.5z'/%3e%3c/svg%3e" 28px 28px;
$phone: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22' height='28'%3e%3cpath fill='white' d='M22 19.375c0 .562-.25 1.656-.484 2.172-.328.766-1.203 1.266-1.906 1.656-.922.5-1.859.797-2.906.797-1.453 0-2.766-.594-4.094-1.078a14.627 14.627 0 0 1-2.734-1.297c-2.656-1.641-5.859-4.844-7.5-7.5a14.627 14.627 0 0 1-1.297-2.734C.595 10.063.001 8.75.001 7.297c0-1.047.297-1.984.797-2.906.391-.703.891-1.578 1.656-1.906.516-.234 1.609-.484 2.172-.484.109 0 .219 0 .328.047.328.109.672.875.828 1.188.5.891.984 1.797 1.5 2.672.25.406.719.906.719 1.391 0 .953-2.828 2.344-2.828 3.187 0 .422.391.969.609 1.344 1.578 2.844 3.547 4.813 6.391 6.391.375.219.922.609 1.344.609.844 0 2.234-2.828 3.187-2.828.484 0 .984.469 1.391.719.875.516 1.781 1 2.672 1.5.313.156 1.078.5 1.188.828.047.109.047.219.047.328z'/%3e%3c/svg%3e" 22px 28px;

@mixin inline-svg($name, $color: null) {
	@if ($color) {
		$color: str-replace($color + '', '#', '');
		background: transparent url(str-replace(nth($name, 1), '%23FFF', '%23' + $color)) no-repeat 50% 50%;
	} @else {
		background: transparent url(nth($name, 1)) no-repeat 50% 50%;
	}
	background-size: 100%;
	width: nth($name, 2);
	height: nth($name, 3);
}

@function inline-svg-width($name) {
	@return nth($name, 2);
}

@function inline-svg-height($name) {
	@return nth($name, 3);
}
